import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import type {
  IEditUserRequest,
  ILoginRequest,
  IRegisterRequest,
  ISubscriptionUpdateRequest,
} from '~/interfaces/dto/auth/request';
import type {
  ILoginResponse,
  IRegisterResponse,
  IUserInfoExtendedResponse,
} from '~/interfaces/dto/auth/response';
import { useFetch } from '#imports';
import { useServices } from '../useServices';
import { brandIds } from 'assets/data/regions';

export const useAuthService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.authApi);
  const authStore = useAuthStore();
  const siteStore = useSiteStore();

  const retryAuthenticatedRequest = useRetryAuthenticatedRequest;

  const register = async (
    request: IRegisterRequest
  ): Promise<IRegisterResponse> => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/register` });
      const { username, countryCode, password } = request;
      const { data } = await useFetch<IRegisterResponse>(requestUrl, {
        method: 'post',
        body: request,
        headers: {
          'Cache-Control': 'no-cache',
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        async onResponse({ response }) {
          if (!!response._data && response?._data?.isSuccessful) {
            await login({ username, countryCode, password }).then(() => {
              siteStore.deactivateModal();
            });
          } else {
            //Google Analytics for reg failing?
            console.error(response?._data?.error);
          }
          return response._data;
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const login = async (request: ILoginRequest): Promise<ILoginResponse> => {
    const res: globalThis.Ref<ILoginResponse> = ref();
    const { $walletService } = useServices();
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/authenticate` });
      await useFetch<ILoginResponse>(requestUrl, {
        method: 'post',
        body: request,
        headers: {
          Accept: 'application/json',
          'Cache-Control': 'no-cache',
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        async onResponse({ response }) {
          if (!!response._data.data?.jwtToken) {
            authStore.setAccessToken(response._data.data.jwtToken);
            authStore.setRefreshToken(response._data.data.refreshToken);
            authStore.setUserId(response._data.data.id);
            authStore.setLoggedIn();
            await getUserExtended();
            // if (!!user.data) {
            //   await trackingMethods.identifyUser(user.data.id, user.data);
            // }
            await $walletService.fetchBalances();
          }
          res.value = response._data;
          return response._data;
        },
      });

      return res.value;
    } catch (e) {
      console.error(e);
      return e;
    }
  };
  const handleLoginSession = async (queryStr: string) => {
    const response: ILoginResponse['data'] = JSON.parse(atob(queryStr));
    const { $walletService } = useServices();
    authStore.setAccessToken(response?.jwtToken);
    authStore.setRefreshToken(response?.refreshToken);
    authStore.setUserId(response?.id);
    authStore.setLoggedIn();
    await getUserExtended();
    await $walletService.fetchBalances();
  };

  const refreshToken = async () => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/refresh-token` });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        body: {},
        headers: {
          'Refresh-Token': `${authStore.currentRefreshToken}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        server: false,
        onResponse({ response }) {
          if (!!response._data.error) authStore.logout();
          const accessToken = response._data.data.jwtToken;
          const refreshToken = response._data.data.refreshToken;
          authStore.setAccessToken(accessToken);
          authStore.setRefreshToken(refreshToken);
        },
        onRequestError() {
          authStore.logout();
        },
        onResponseError() {
          authStore.logout();
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  // user details
  const getUser = async () => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/user-info` });
      return await retryAuthenticatedRequest(requestUrl, {
        headers: {
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        onResponse({ response }) {
          authStore.setUser(response._data.data);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const getUserExtended = async () => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/user-info-extended`,
      });
      return await retryAuthenticatedRequest<IUserInfoExtendedResponse>(
        requestUrl,
        {
          onResponse({ response }) {
            authStore.setUser(response._data.data);

            //set user compliant status for red dot alert on profile and doc-ver
            if (response._data.data.compliance) {
              authStore.setDocumentVerificationStatus(
                !(response._data.data.compliance.complianceStatus < 1)
              );
              authStore.setPartialPostReg(
                response._data.data.compliance.complianceStatus < 1
              ); //If not Fica'd set partial post reg to true - this is to cover user refreshes during post reg
            }
            //Check if deceased or minor and log them out
            if (
              response?._data?.data?.compliance.hasOwnProperty('isValidId') &&
              !response?._data?.data?.compliance?.isValidId
            ) {
              authStore.logout();
            }
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  };
  const editUser = async (request: IEditUserRequest) => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/edit-user` });
      const { data } = await useFetch(requestUrl, {
        method: 'put',
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        onResponse({ response }) {
          authStore.setUser(response._data);
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  const addMobileNumber = async ({ accountId, countryCode, mobileNumber }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Contact/mobile-number/add`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        body: {
          accountId,
          countryCode,
          mobileNumber,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponse({ response }) {
          authStore.setUser(response._data);
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  const deleteMobileNumber = async ({
    accountId,
    countryCode,
    mobileNumber,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Contact/mobile-number/remove`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        body: {
          accountId,
          countryCode,
          mobileNumber,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponse({ response }) {
          authStore.setUser(response._data);
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  // password functions
  const passwordResetInit = async ({
    login,
    countryCode,
    contactPreferences,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/password-change/initiate`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        body: {
          login,
          countryCode,
          contactPreferences,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  const passwordResetConfirm = async ({
    password,
    login,
    countryCode,
    token,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/password-change/confirm`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        body: {
          password,
          login,
          countryCode,
          token,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  const passwordResetLoggedIn = async ({
    accountId,
    countryCode,
    password,
    confirmPassword,
  }) => {
    try {
      const requestUrl = base.requestUrl({
        path: `v1/Users/password-change`,
      });
      const { data } = await useFetch(requestUrl, {
        method: 'post',
        body: {
          accountId,
          countryCode,
          password,
          confirmPassword,
        },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  // subscriptions
  const fetchSubscriptions = async () => {
    try {
      const requestUrl = base.generateUrl({
        baseUrl: appConfig.public.base,
        path: 'api/v1/Subscription/Notification',
      });
      const { data } = await useFetch(requestUrl, {
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };
  const updateSubscriptions = async (request: ISubscriptionUpdateRequest) => {
    try {
      const requestUrl = base.generateUrl({
        baseUrl: appConfig.public.base,
        path: 'api/v1/Subscription/Notification',
      });
      const { data } = await useFetch(requestUrl, {
        method: 'put',
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUserLocks = async () => {
    try {
      const requestUrl = base.requestUrl({ path: `v1/Users/locks` });
      const { data } = await useFetch(requestUrl, {
        method: 'get',
        body: { accountId: authStore.user_id() },
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
          'X-Brand-Id': brandIds[siteStore.getRegionCode],
        },
        onResponse({ response }) {
          if (data.isActive) {
            authStore.setLockStatus(data.lockAccess);
          } else {
            authStore.setLockStatus(-1);
          }
        },
      });
      return data.value;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    register,
    login,
    handleLoginSession,
    getUser,
    getUserExtended,
    editUser,
    refreshToken,
    passwordResetLoggedIn,
    passwordResetInit,
    passwordResetConfirm,
    fetchSubscriptions,
    updateSubscriptions,
    addMobileNumber,
    deleteMobileNumber,
    fetchUserLocks,
  };
};
